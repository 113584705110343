
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import MenuList from '../menu';
import { menus } from '../../fakeData/sideBar';
import { useUserSignOut, usePinSideBar } from '@oneblinktech-org/helios-base'
import { useDispatch, useSelector } from 'react-redux';
import { createApiClient } from '@oneblinktech-org/helios-base';
import { transformMenu } from './transformMenu';
import {
    setGroup,
    setGroupMenu
  } from '../../store/profile/actions'
import Referral from '../../assets/images/referrals.svg'
import Left from '../../assets/images/left.svg'
import PastConnections from "../../assets/images/past_connections.svg";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isMobile;
};
  
const LeftSideComponent = () => {

    const [menuPinned, setMenuPinned] = useState(false)
    const myGroupsMenu = useSelector((state) => state.profile.groupsMenu)
    const user = useSelector((state) => state.profile.user.data)
    const isMobile = useIsMobile();
    const token = localStorage.getItem('h-access_token');

    const dispatch = useDispatch()
    const userSignOut = useUserSignOut();
    const pinSidebar = usePinSideBar(true);
    const initialPinSidebar = usePinSideBar(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { get } = createApiClient({ apiUrl });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getMyGroup();
    }, [user])

    useEffect(() => {
        initialPinSidebar()
    }, [isMobile])
    
    const getMyGroup = async () => {
        try {
            if (user.id && !loading) {
                setLoading(true)
                const token = localStorage.getItem('h-access_token')
                const result = await get('/ithaca/users/myGroups/' + user.id, {
                    "Authorization": `Bearer ` + token
                });
                dispatch(setGroup(result))
                dispatch(setGroupMenu(transformMenu(result)))
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const isOpen = () => {
        if(isMobile) {
            pinSidebar()
        }
    }
    
    return (
        <div className="hel-sidebar">
            <div className="hel-sidebar-logo d-flex justify-content-between d-block d-xl-none">
            </div>
            <div id="sidebar-scrollbar">
                <div className="sidebar-profile">
                    <div className="profile-user-avatar">
                        <img alt="" src={user?.full_profile_image_url || require('../../assets/images/skel-user.png').default} />
                        {/* <div className="mark-green" /> */}
                    </div>
                    <div className="sidebar-profile-info">
                        <h6 className="title">{user?.firstname} {user?.lastname}</h6>
                        <div className='d-flex flex-row align-items-center justify-content-between'>
                            <Link to="/profile" onClick={() => isOpen()}><span className="profile-link">View Profile</span></Link>
                        </div>
                    </div>
                </div>
                <nav className="hel-sidebar-menu">
                    <div>
                        <span className="sidebar-title">
                            <div className="menu-pin" onClick={() => pinSidebar()}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                            <ul className={"hel-menu"} id="hel-sidebar-toggle">
                                <div className='menu-list' >
                                    <Link to={'/sendReferral'} className='send-ref' onClick={() => isOpen()}>
                                        <img alt="" src={Referral} className="menu-icon" />
                                        <span>Send Referral</span>
                                    </Link>
                                    <Link to={'/pastReferral'} className='send-ref' onClick={() => isOpen()}>
                                        <img alt="" src={PastConnections} className="menu-icon" />
                                        <span>My Referrals</span>
                                    </Link>
                                    <Link to={'/my-messages'} className='send-ref' onClick={() => isOpen()}>
                                        <i className="menu-icon bi bi-envelope"></i> <span>Group Messages</span>
                                    </Link>
                                    <Link to={'/goals'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-bullseye"></i> <span>Goals</span>
                                    </Link>
                                    <Link to={'/gratitudes'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-emoji-laughing"></i> <span>Gratitudes</span>
                                    </Link>
                                    <Link to={'/my-events'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-calendar-week"></i> <span>My Events</span>
                                    </Link>

                                    <Link to={'/subscriptions'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-currency-dollar"></i> <span>My Subscriptions</span>
                                    </Link>
                                    <Link to={'/applications'} className='send-ref' onClick={() => isOpen()}>
                                         <i className="menu-icon bi bi-file-earmark-text"></i> <span>Applications</span>
                                    </Link>

                                  {user?.user_setting?.legacy_roles?.includes(99) && (
                                    <>
                                    <Link to={'/chair-events'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-calendar3"></i> <span>Chair: Events</span>
                                    </Link>
                                    <Link to={'/chair-registered-guests'} className='send-ref' onClick={() => isOpen()}>
                                        <i class="menu-icon bi bi-file-earmark-person"></i> <span>Chair: Registered Guests</span>
                                    </Link>
                                    </>
                                  )}
                                    <a href={`https://${window.location.hostname.startsWith('ithaca-stage') ? 'key-stage' : 'admin'}.${window.location.hostname.split('.').slice(-2).join('.')}/my2/sso-login?token=${token}`} target="_blank" rel="noopener noreferrer" className='send-ref'>
                                        <i className="menu-icon bi bi-gear-fill"></i> <span>Admin Site</span> <i className="bi bi-box-arrow-up-right"></i>
                                      </a>

                                </div>
                                <MenuList items={myGroupsMenu} className={"hel-menu"} visible={true} id="hel-sidebar-toggle" />
                            </ul>
                        </span>
                    </div>
                </nav>
                {/* {
                    user?.id ?
                    <div className='log-out_mobile' onClick={() => {dispatch({type: "SET_PROFILE"}); userSignOut()}}>
                        <img src={require('../../assets/images/logout.svg').default} />
                        <span className="profile-link">Log out</span>
                    </div>
                    :
                    <Link to="/login" className='log-out_mobile'>
                        <img src={require('../../assets/images/logout.svg').default} />
                        <span className="profile-link">Log In</span>
                    </Link>
                } */}
            </div>
            <div className='overlayed' onClick={() => pinSidebar()}></div>
        </div>
    )
}

export default LeftSideComponent
import React, { useEffect, useState } from 'react'
import EditProfileUi from '../components/EditProfile'
import { Spin, notification } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ADD_USER } from '../store/profile/actionTypes'
import { createApiClient } from '@oneblinktech-org/helios-base';

const EditProfile = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.profile.user)

    const apiUrl = process.env.REACT_APP_API_URL;
    const { get } = createApiClient({ apiUrl });
    const token = localStorage.getItem('h-access_token')
    const dispatch = useDispatch()
    
    useEffect(() => {
        document.title = 'Edit My Profile'
    },[])
    
    return (
        <div className='flex h-screen md-flex-wrap edit_profile'>
            <EditProfileUi />

        </div>
    )
}

export default EditProfile
import React, { useEffect, useState } from 'react'
import PortalPopup from '../components/PortalPopup/PortalPopup'
import { getGroup, getGroupMembers } from '../services/groups_connection';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ThankYou from '../components/ThankYou';
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Spin } from 'antd'
import { useLocation, Redirect } from 'react-router-dom'
import { ReactComponent as ArrowDown } from "../assets/images/path.svg"
import { createApiClient } from '@oneblinktech-org/helios-base';
import { NotificationManager } from 'react-notifications';
import { dateFormat } from '../helpers/dateFormat';
import MyGroups from '../components/MyGroups';


function SendReferral() {
    const [isShowMembers, setShowMembers] = useState(false);
    const [showThankyou, setShowThankyou] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [valueEditDisabled, setValueEditDisabled] = useState(false);
    const [submitBtnText, setSubmitBtnText] = useState("Send Referral");

    const [group, setGroup] = useState(null)
    const [members, setMembers] = useState([])
    const [membersAll, setMembersAll] = useState([])
    const { register, handleSubmit, formState: { errors, isDirty, isSubmitting }, watch, setValue, reset, control } = useForm({
        defaultValues: {
            createdAt: new Date(),
            description: '',
            email: '',
            name: '',
            phone: '',
            value: '',
        }
    })
    const user = useSelector((state) => state?.profile?.user?.data)
    const { search, state } = useLocation()
    const searchParams = new URLSearchParams(search);
    let paramsGroupId = searchParams.get("groupId");
    const memberId = searchParams.get("memberId");
    const apiUrl = process.env.REACT_APP_API_URL;
    const { post, get } = createApiClient({ apiUrl });
    const [groupId, setGroupId] = useState('')
    const [otherMembers, setOtherMembers] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const { referral_id } = state || {};

    useEffect(() => {
        document.title = 'Send Referral - or View/Edit Referral'
    }, [])

    useEffect(() => {
        if (paramsGroupId)
            setGroupId(paramsGroupId)
        else
            setGroupId('')
    }, [paramsGroupId])

    useEffect(() => {
        const init = async () => {
            if (groupId) {
                try {
                    await setShowMembers(false)
                    await setLoading(true)
                    const result = await getGroup(groupId)
                    if (result)
                        await setGroup(result)
                    const members = await getGroupMembers(groupId)
                    if (members) {
                        var memberList = await Object.values(members)
                        memberList = await memberList?.filter(member => member?.id !== user?.id)
                        if (memberId) {
                            setValue("to", memberList.filter(member => member?.id == memberId)?.[0])
                        }
                        await setMembers(memberList)
                        await setMembersAll(memberList)
                    }
                    await setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            } else {
                setGroupId('')
                setShowMembers(false)
                setMembers([])
                setMembersAll([])
            }
        }
        init()
    }, [groupId])

    useEffect(() => {
        if (referral_id)
            getReferreal()
    }, [referral_id])

    async function getReferreal() {
        setLoading(true)
        const token = await localStorage.getItem('h-access_token')
        const res = await get(`ithaca/referrals/get/${referral_id}`, {
            "Authorization": `Bearer ` + token
        })
        if (res) {
            let to_user_avatar = ''
            let from_user_avatar = ''

            if (res.to_user_id !== user.id) {
                to_user_avatar = await getUserInfo(res.to_user_id, 'to')
            } else {
                to_user_avatar = user?.full_profile_image_url
            }
            if (res.from_user_id !== user.id) {
                from_user_avatar = await getUserInfo(res.from_user_id, 'from')
            } else {
                from_user_avatar = user?.full_profile_image_url
            }

            await reset({
                from: { id: res?.from_user_id, name: res?.from_name, full_profile_image_url: from_user_avatar },
                description: res?.description,
                email: res?.email,
                name: res?.referral_name,
                phone: res?.phone_number,
                to: { id: res?.to_user_id, name: res?.to_name, full_profile_image_url: to_user_avatar },
                value: res?.value,
                createdAt: res?.created_at
            })

            if (user?.id == res?.from_user_id) {
                setEditDisabled(true);
            }

            if (user?.id == res?.to_user_id) {
                setSubmitBtnText("Save Changes");
                setValueEditDisabled(true)
            }
        }
        setLoading(false)
    }

    async function getUserInfo(user_id, user_type) {
        const token = await localStorage.getItem('h-access_token')
        const res = await get(`ithaca/users/get/${user_id}`, {
            "Authorization": `Bearer ` + token
        })
        if (res) {
            return res?.full_profile_image_url
        }
    }

    function closeThankYou() {
        setShowThankyou(false)
    }

    async function submitSendConnection(data) {
        const token = localStorage.getItem('h-access_token')
        if (referral_id) {
            try {
                const result = await post('/ithaca/referrals/update', {
                    id: referral_id,
                    value: data?.value,
                }, {
                    "Authorization": `Bearer ` + token
                })
                if (result.status == 200) {
                    setShowThankyou(true)
                }
            } catch (error) {
                let errs = error?.response?.data?.errors;
                let msg = error?.response?.data?.message;

                if (errs) {
                    Object.keys(errs).forEach(field => {
                        const messages = errs[field];
                        messages.forEach(message => {
                            NotificationManager.error(message, msg, 7000);
                        });
                    });
                } else {
                    NotificationManager.error('An error occurred', 'Error', 7000);
                }
            }
        } else {
            try {
                const result = await post('/ithaca/referrals/save', {
                    'to_user_id': data?.to?.id,
                    'referral_name': data?.name,
                    'description': data?.description,
                    'phone_number': data?.phone,
                    'email': data?.email,
                    'value': data?.value,
                    'group_id': groupId
                }, {
                    "Authorization": `Bearer ` + token
                })
                if (result.status == 200) {
                    setShowThankyou(true)
                }
            } catch (error) {
                let errs = error?.response?.data?.errors;
                let msg = error?.response?.data?.message;

                if (errs) {
                    Object.keys(errs).forEach(field => {
                        const messages = errs[field];
                        messages.forEach(message => {
                            NotificationManager.error(message, msg, 7000);
                        });
                    });
                } else {
                    NotificationManager.error('An error occurred', 'Error', 7000);
                }
            }
        }
    }

    const watchAllFields = watch()

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchTerm.length >= 3) {
                try {
                    const token = await localStorage.getItem('h-access_token')
                    const response = await get(`ithaca/users/getActiveMembers?search=${searchTerm}`, {
                        "Authorization": `Bearer ` + token
                    })
                    if (response) {
                        setOtherMembers(Object.values(response))
                    }

                    const members = await membersAll.filter(member => member.name.toLowerCase().includes(searchTerm))
                    await setMembers(members)
                } catch (e) {
                    throw e
                }
            } else {
                setMembers(membersAll)
                setOtherMembers([])
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleMemberSelection = async (item) => {
        setValue('to', item)
        setShowMembers(false)
        setOtherMembers([])
        setMembers(membersAll)
    }

    const handleBlur = (event) => {
        // if the blur was because of outside focus
        // currentTarget is the parent element, relatedTarget is the clicked element
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowMembers(false)
        }
    }

    return (
        <Spin spinning={loading} size='large'>
            <div className='flex h-screen md-flex-wrap'>
                <div className="connection-container">
                    {!referral_id &&
                        <div className="label-container md-w-full d-flex flex-column flex-sm-row align-items-start justify-content-between align-items-center">
                            <h1 className="connection-header-label">{`${submitBtnText}:`}</h1>
                            <MyGroups groupId={groupId} setGroupId={setGroupId} />
                        </div>
                    }
                    <div className='connection-frame'>
                        <div className='container-content' >
                            <div className='connection-poster-wrap'>
                                <img className='connection-poster' src={require("../assets/images/send_referral.svg").default} />
                            </div>
                            <div className='group-child' >
                                <div class="referral-text-parent">
                                    <div className='referral-text-col' >
                                        <strong class="referral-text">Referral Connection</strong>
                                        <span className='description-text'>Send a referral with an estimated value to a member for follow-up and confirmation.</span>
                                    </div>
                                    <div class="date">{referral_id ? dateFormat(watch('createdAt'), "USER_LOGIN_DATE_FORMAT") : dateFormat(new Date(), "USER_LOGIN_DATE_FORMAT")}</div>
                                </div>
                                <form className='refferal-form' onSubmit={handleSubmit(submitSendConnection)} autoComplete='off' >
                                    <div className='form-row-ref' tabindex="-1" onBlur={handleBlur}>
                                        <div class="connection-name-parent border-none">
                                            <b class="name">From</b>
                                            <div class="connection-frame-group">
                                                <div class="user-img-wrapper">
                                                    <img class="user-img-con" alt="" src={referral_id ? (watch('from')?.full_profile_image_url ? watch('from')?.full_profile_image_url : require('../assets/images/skel-user.png').default) : user?.full_profile_image_url ? user?.full_profile_image_url : require('../assets/images/skel-user.png').default} />
                                                </div>
                                                <div class="user-title">{referral_id ? watch('from')?.['name'] : `${user?.firstname} ${user?.lastname}`}</div>
                                            </div>
                                        </div>
                                        <div class={`connection-name-parent-to ${editDisabled || valueEditDisabled ? ' field_disabled' : ''}`}>
                                            <b class="name">To</b>
                                            <div className='to-input'>
                                                {isShowMembers ?
                                                    <div className={`connection-frame-input-to`}>
                                                        <input autoFocus class="user-to" defaultValue={watch('to') ? `${watch('to')?.name}` : ''} onChange={(e) => setSearchTerm(e.target.value)} />
                                                        {(members.length > 0 || otherMembers.length > 0) && (<ArrowDown class="arrow-down" fill='var(--granite-gray)' onClick={() => setShowMembers(false)} />)}
                                                    </div>
                                                    :
                                                    <div class={`connection-frame-input-to `} style={{ cursor: 'pointer', width: '-webkit-fill-available' }}
                                                        onClick={() => {
                                                            if (!editDisabled && !valueEditDisabled) {
                                                                setShowMembers(!isShowMembers)
                                                            }
                                                        }}
                                                    >
                                                        <div class="user-img-wrapper">
                                                            {watch('to') ? <img class="user-img-con" alt="" src={watch('to')?.full_profile_image_url ? watch('to')?.full_profile_image_url : require('../assets/images/skel-user.png').default} /> : null}
                                                        </div>
                                                        <div class="user-to">{watch('to') ? `${watch('to')?.name}` : ''}</div>
                                                        {(members.length > 0 || otherMembers.length > 0) && (<ArrowDown class="arrow-down" fill='var(--granite-gray)' />)}
                                                    </div>
                                                }
                                            </div>
                                            {isShowMembers && (members?.length > 0 || otherMembers?.length > 0) && (
                                                <div className='members-select'>
                                                    {members?.length > 0 && (
                                                        <div className='to-title' >
                                                            <strong>{group?.name}</strong>
                                                        </div>
                                                    )}
                                                    {members?.map((item, index) => {
                                                        return (
                                                            <div className='member-item' key={index} onClick={() => handleMemberSelection(item)} >
                                                                <img class="user-img-con" alt="" src={item?.full_profile_image_url} />
                                                                <div>{`${item?.name}`}</div>
                                                            </div>
                                                        )
                                                    })}
                                                    {otherMembers?.length > 0 && (
                                                        <div className='to-title' >
                                                            <strong>Others</strong>
                                                        </div>
                                                    )}
                                                    {otherMembers?.map((item, index) => {
                                                        return (
                                                            <div className='member-item' key={index} onClick={() => handleMemberSelection(item)} >
                                                                <img class="user-img-con" alt="" src={item?.full_profile_image_url} />
                                                                <div>{`${item?.name}`}</div>
                                                            </div>
                                                        )
                                                    })}
                                                    {/* <div className='to-title' >
                                                        <strong>Others</strong>
                                                    </div> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row-ref'>
                                        <div className={`input-item ${editDisabled || valueEditDisabled ? ' field_disabled' : ''}`} >
                                            <div class="connection-name-parent">
                                                <b className="name">Referral Name*</b>
                                                <div class="connection-frame-input">
                                                    <input
                                                        disabled={editDisabled || valueEditDisabled}
                                                        className='connection-input'
                                                        type="text"
                                                        {...register('name', { required: { value: true, message: 'Referral Name is required' } })}
                                                        maxLength={50}
                                                    />
                                                    <div className='input-bottom'>
                                                        {errors?.name && <p className='error-label'>{errors?.name?.message}</p>}
                                                        <div className="name1">{`${watch('name')?.length}/${50}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`input-item ${editDisabled ? ' field_disabled' : ''}`} >
                                            <div class="connection-name-parent">
                                                <b className="name">Value</b>
                                                <div class="connection-frame-input">
                                                    <Controller
                                                        render={(props) => {
                                                            return (
                                                                <NumberFormat
                                                                    className='connection-input'
                                                                    thousandSeparator={true}
                                                                    disabled={editDisabled}
                                                                    prefix={"$ "}
                                                                    onValueChange={(v) => {
                                                                        //value without dollar signe
                                                                        setValue('value', v.value);
                                                                    }}
                                                                    variant="outlined"
                                                                    value={props?.field?.value}
                                                                    {...props}
                                                                    {...register('value', { required: { value: true, message: 'Value is required' } })}
                                                                    allowEmptyFormatting
                                                                />
                                                            )
                                                        }}
                                                        name={'value'}
                                                        control={control}
                                                        rules={{ required: { value: true, message: 'Value is required' } }}
                                                    />
                                                    <div className='input-bottom'>
                                                        {errors?.value && <p className='error-label'>{errors?.value?.message}</p>}
                                                        <div className="name1">{`${watch('value')?.length ? watch('value')?.length : 0}/${30}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-row-ref'>
                                        <div className={`input-item ${editDisabled || valueEditDisabled ? ' field_disabled' : ''}`} >
                                            <div class="connection-name-parent">
                                                <b className="name">Phone number</b>
                                                <div class="connection-frame-input">
                                                    <input
                                                        className='connection-input'
                                                        type="text"
                                                        disabled={editDisabled || valueEditDisabled}
                                                        {...register('phone', { required: { value: true, message: 'Phone is required' } })}
                                                        maxLength={50}
                                                    />
                                                    <div className='input-bottom'>
                                                        {errors?.phone && <p className='error-label'>{errors?.phone?.message}</p>}
                                                        <div className="name1">{`${watch('phone')?.length}/${50}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`input-item ${editDisabled || valueEditDisabled ? ' field_disabled' : ''}`} >
                                            <div class="connection-name-parent">
                                                <b className="name">Email</b>
                                                <div class="connection-frame-input">
                                                    <input
                                                        className='connection-input'
                                                        type="text"
                                                        disabled={editDisabled || valueEditDisabled}
                                                        {...register('email', {
                                                            required: { value: true, message: 'Email is required' },
                                                            pattern: { value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, message: 'Invalid email' }
                                                        })}
                                                        maxLength={100}
                                                    />
                                                    <div className='input-bottom'>
                                                        {errors?.email && <p className='error-label'>{errors?.email?.message}</p>}
                                                        <div className="name1">{`${watch('email')?.length}/${100}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-row-ref'>
                                        <div className={`input-item ${editDisabled || valueEditDisabled ? ' field_disabled' : ''}`} >
                                            <div class="connection-name-parent">
                                                <b className="name">description*</b>
                                                <div class="connection-frame-input">
                                                    <textarea
                                                        className='connection-input'
                                                        type="text"
                                                        disabled={editDisabled || valueEditDisabled}
                                                        {...register('description', { required: { value: true, message: 'Description is required' } })}
                                                        maxLength={1000}
                                                        rows={3}
                                                    />
                                                    <div className='input-bottom'>
                                                        {errors?.description && <p className='error-label'>{errors?.description?.message}</p>}
                                                        <div className="name1">{`${watch('description')?.length}/${1000}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        (!editDisabled) &&
                                        <div class="connection-name-parent border-none">
                                            <button disabled={(isDirty && !isSubmitting) ? false : true} class={`send-connection-button ${(!isDirty || isSubmitting) ? 'disable_btn' : ''}`} type='submit'>
                                                {isSubmitting ?
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    :
                                                    submitBtnText
                                                }
                                            </button>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {showThankyou && (
                    <PortalPopup
                        overlayColor={`var(--color-gray-500)`}
                        placement="Centered"
                        onOutsideClick={closeThankYou}
                        zIndex={9999}
                    >
                        <ThankYou closeThankYou={closeThankYou} reset={reset} groupId={group?.id} alert={editDisabled || valueEditDisabled} />
                    </PortalPopup>)}
            </div>
        </Spin>
    )
}

export default SendReferral
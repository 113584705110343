import { takeLatest, put, call } from 'redux-saga/effects'
import { 
    GET_PROFILE,
    UPDATE_PROFILE,
} from './actionTypes'

import {
    getProfileSuccess,
    getProfileFailed,
    updateProfileSuccess,
    updateProfileFailed
} from "./actions"

import { getProfile, updateProfile } from "../../services/user"

function* onGetProfile(data) {

    try {
        const response = yield call(getProfile);
        yield put(getProfileSuccess(response));
    } catch (error) {
        yield put(getProfileFailed(error.response));
    }
}

function* onUpdateProfile(data) {

    try {
        const response = yield call(updateProfile, data.payload);
        yield put(updateProfileSuccess(response.data));
    } catch (error) {
        yield put(updateProfileFailed(error.response));
    }
}

function* ProfileSaga() {
    yield takeLatest(GET_PROFILE, onGetProfile)
    yield takeLatest(UPDATE_PROFILE, onUpdateProfile)
}

export default ProfileSaga;
import React from 'react';

const ChairEvents = () => {
    const token = localStorage.getItem('h-access_token');
    const getMainDomain = (hostname) => {
        const parts = hostname.split('.');
        return parts.slice(-2).join('.');
    };
    const mainDomain = getMainDomain(window.location.hostname);
    const subdomain = window.location.hostname.startsWith('ithaca-stage') ? 'key-stage' : 'admin';
    const adminDomain = `${subdomain}.${mainDomain}`
    const url = `https://${adminDomain}/my2/chair-events?token=${token}`;

    return (
        <div>
            <iframe src={url} style={{width: '100%', height: 'calc(100vh - 80px)'}} title="Chair Events"></iframe>
        </div>
    );
};

export default ChairEvents;

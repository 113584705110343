import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { Select, Spin, Segmented } from 'antd'
import moment from 'moment'
import { createApiClient } from '@oneblinktech-org/helios-base';
import { ReactComponent as Previoue } from "../assets/images/Previous.svg"
import { ReactComponent as Next } from "../assets/images/next.svg"
import { ReactComponent as ArrowDown } from "../assets/images/path.svg"
import { useHistory } from 'react-router-dom'

import ReactPaginate from 'react-paginate';
import { getGroup } from '../services/groups_connection';
import { useSelector } from 'react-redux';
import MyGroups from '../components/MyGroups';
import { dateFormat } from '../helpers/dateFormat';

const apiUrl = process.env.REACT_APP_API_URL;
const { get } = createApiClient({ apiUrl });

const SortingType = {
    Descending: 'desc', Ascending: 'asc'
}
function PastReferrals() {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const history = useHistory()

    const [sortConfig, updateSortConfig] = useState([]);
    const [referralList, setReferralList] = useState([])
    const [referralDetails, setReferralDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [enableAction, setEnableAction] = useState(false)
    const [page, setPage] = useState(1)
    const [group, setGroup] = useState(null)
    const [referral_id, setReferralId] = useState('')
    const token = localStorage.getItem('h-access_token')
    const [pageShow, setPageShow] = useState(localStorage?.getItem('on-page') ?? 20)
    const user = useSelector((state) => state.profile.user.data)
    const [groupId, setGroupId] = useState(localStorage.getItem('group_id') ?? '')
    const [type, setType] = useState(localStorage.getItem('type') ?? 'Received')

    useEffect(() => {
        document.title = 'Past Referrals'
    }, [])

    useEffect(() => {
        getReferrals(1)
        localStorage.setItem('group_id', groupId)
        if (!groupId || groupId === 'undefined')
            setGroupId('')
    }, [type, groupId])

    async function getReferrals(page, notLoad) {
        try {
            if (!notLoad)
                await setLoading(true)
            await setPage(page)
            const res = await get(`ithaca/referrals/get?page=${page}&group_id=${groupId ? groupId : ''}&sent=${type === 'Sent' ? 1 : 0}&received=${type === 'Received' ? 1 : 0}`, {
                "Authorization": `Bearer ` + token
            })
            if (res) {
                await setReferralList(res.data)
                await setReferralDetails(res)
            }
            await setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const sortBy = useCallback(
        async (propertyName) => {
            let pendingChange = await [...sortConfig];
            let sortedList = await referralList
            const index = await pendingChange.findIndex((config) =>
                config.propertyName === propertyName)
            if (index > -1) {
                //Save the sortType
                var currentSortType = pendingChange[index].sortType;
                //Remove existing config
                pendingChange.splice(index, 1);
                //check if the sort type we saved is descending
                if (currentSortType === SortingType.Descending) {
                    pendingChange = [
                        ...pendingChange,
                        { propertyName: propertyName, sortType: SortingType.Ascending },
                    ];
                    sortedList = await referralList.sort((a, b) => (a[propertyName] > b[propertyName]) ? 1 : -1)
                } else {
                    pendingChange = [
                        ...pendingChange,
                        { propertyName: propertyName, sortType: SortingType.Descending },
                    ];
                    sortedList = await referralList.sort((a, b) => (a[propertyName] > b[propertyName]) ? -1 : 1)
                }
            } else {
                pendingChange = [
                    ...pendingChange,
                    { propertyName: propertyName, sortType: SortingType.Descending },
                ];
                sortedList = await referralList.sort((a, b) => (a[propertyName] > b[propertyName]) ? -1 : 1)
            }
            await updateSortConfig([...pendingChange]);
            await setReferralList(sortedList)
        },
        [sortConfig, referralList]

    )

    async function handleMarkAsRead() {
        try {
            const res = await get(`ithaca/referrals/markAsRead/${referral_id}`, {
                "Authorization": `Bearer ` + token
            })
            if (res?.success) {
                await setEnableAction(false)
                let list = await referralList
                let index = await list?.findIndex(item => item?.id === referral_id)
                if (list[index].read_at === null && index) {
                    list[index].read_at = await new Date()
                    await setReferralList([...list])
                }
            }
        } catch (error) {
            setLoading(false)
            setEnableAction(false)
        }
    }

    return (
        <Spin spinning={loading} size='large'>
            <div className='flex h-screen md-flex-wrap pastreferral' onClick={() => setEnableAction(false)}>
                <div className="profile-info">
                    <div className="label-container md-w-full d-flex flex-column flex-sm-row align-center justify-content-between md-flex-wrap">
                        <div className='flex gap-2 align-items-center md-w-full'>
                            <h1 className="connection-header-label">{`My Referrals: `}</h1>
                            <MyGroups groupId={groupId} setGroupId={setGroupId} enableShowAll={true} />
                        </div>
                        <Segmented
                            defaultValue={type}
                            options={[{ label: 'Sent', value: 'Sent' }, { label: 'Received', value: 'Received' }]}
                            onChange={(e) => {
                                localStorage.setItem('type', e)
                                setType(e)
                            }}
                        />
                        <div className='show-page mb-2 mb-sm-0' >
                            <strong>Show on page</strong>
                            <Select defaultValue={pageShow} className='past-per-page-list' onChange={(value) => {
                                setPageShow(value)
                                localStorage.setItem('on-page', value)
                            }} >
                                <Select.Option value={20} >20</Select.Option>
                                <Select.Option value={50} >50</Select.Option>
                                <Select.Option value={100} >100</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className='past-connection-frame'>
                        <div className='past-tabel' >
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => sortBy('to_name')}>TO<ArrowDown class="arrow-icon" /></th>
                                        <th onClick={() => sortBy('from_name')}>FROM<ArrowDown class="arrow-icon" /></th>
                                        <th onClick={() => sortBy('referral_name')}>REFERRAL NAME<ArrowDown class="arrow-icon" /></th>
                                        <th onClick={() => sortBy('value')} style={{ textAlign: 'right' }} >VALUE<ArrowDown class="arrow-icon" /></th>
                                        <th onClick={() => sortBy('created_at')}>{type}<ArrowDown class="arrow-icon" /></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referralList && referralList?.map((item, index) => {
                                        return (
                                            <tr className={!item?.read_at && item?.to_user_id === user?.id ? 'unread' : 'connecton-row'} key={index} onClick={() => history.push({ pathname: "/sendReferral", search: `?groupId=${groupId}`, state: { referral_id: item.id } })}>
                                                <td className={!item?.read_at && item?.to_user_id === user?.id ? 'to-text font-weight-bold' : 'to-text'}>{item.to_name}</td>
                                                <td className={!item?.read_at && item?.to_user_id === user?.id ? 'to-text font-weight-bold' : 'to-text'}>{item.from_name}</td>
                                                <td className={!item?.read_at && item?.to_user_id === user?.id ? 'to-text font-weight-bold' : 'to-text'}>{item.referral_name}</td>
                                                <td className={!item?.read_at && item?.to_user_id === user?.id ? 'to-text font-weight-bold' : 'to-text'} style={{ textAlign: 'right' }}>
                                                    ${parseFloat(item.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                <td className={!item?.read_at && item?.to_user_id === user?.id ? 'font-weight-bold' : 'font-weight-normal'}>{moment(item.created_at).format('MMM DD, YYYY')}</td>
                                                <td className='relative' title={item?.read_at ? dateFormat(item?.read_at, 'USER_LOGIN_DATE_TIME_FORMAT') : ''}
                                                    style={{ cursor: item?.to_user_id === user?.id ? 'pointer' : 'default' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (item?.to_user_id === user?.id)
                                                            handleMarkAsRead()
                                                    }} >
                                                    <img
                                                        className='icon-more'
                                                        src={require(item?.read_at ? '../assets/images/email-open.svg' : '../assets/images/read.svg').default}
                                                        alt='read'
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            <div className='past-footer' >
                                <button disabled={page === 1} className='first-last' onClick={() => getReferrals(1)}>First</button>
                                <div className='flex gap-3'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel={<Next fill='var(--trustegrity)' height={'2.25rem'} />}
                                        pageCount={referralDetails?.last_page}
                                        previousLabel={<Previoue fill='var(--trustegrity)' height={'2.25rem'} />}
                                        renderOnZeroPageCount={null}
                                        className='pagination-main'
                                        nextClassName='icon-next'
                                        previousClassName='icon-prev'
                                        pageClassName='page-item'
                                        activeClassName='active-page'
                                        breakClassName='page-item'
                                        disabledClassName={'disable-pagination'}
                                        onPageChange={(data) => getReferrals(data?.selected + 1)}
                                        forcePage={page - 1}
                                    />
                                </div>
                                <button disabled={page === referralDetails?.last_page || !referralDetails?.last_page_url} class="first-last" onClick={() => getReferrals(referralDetails?.last_page)} >Last</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default PastReferrals
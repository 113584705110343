import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createApiClient } from '@oneblinktech-org/helios-base';
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications';

const ResetPassword = (props) => {

    const {
        register,
        watch,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({});

    const apiUrl = process.env.REACT_APP_API_URL;
    const { post } = createApiClient({ apiUrl });
    const user = useSelector((state) => state.profile.user)

    const newPassword = watch('newPassword');

    const onSaveChanges = async (data) => {
        try {
            const token = localStorage.getItem('h-access_token')
            const result = await post('/ithaca/users/resetPassword', {
                current_password: data.currentPassword,
                new_password: data.newPassword,
                repeat_password: data.repeatPassword,
            }, {
                "Authorization": `Bearer ` + token
            });

            if(result?.status == 200) {
                NotificationManager.success(result?.data?.message, "Success", 7000);
                props.onCancel();
            } else {
                NotificationManager.error(result?.data?.message, "Error", 7000);
            }
        } catch (error) {
            let err = error?.response?.data?.error;

            if(err) {
                NotificationManager.error(err, "Error", 7000);
            } else {
                NotificationManager.error("An error occurred", "Error", 7000);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSaveChanges)} className='reset_container'>
            <div className='fields_group'>
                <h2>Change Password</h2>
                <div className="component-693 group-inp">
                    <div className="frame-n-a-m-e label">
                        <b className="name">CURRENT PASSWORD</b>
                    </div>
                    <div className="frame-frame-l-i-n-e-title-comp">
                        <div className="frame-f-r-a-m-e">
                            <div className="line-title-component" />

                            <input
                                className="title5"
                                placeholder=""
                                type="password"
                                name={'currentPassword'}
                                {...register('currentPassword', {
                                    required: { value: true, message: 'Current password is required' }
                                })}
                            />
                        </div>
                        <div className="validation">{errors?.currentPassword && <small className='text-danger error-text'>{errors?.currentPassword.message}</small>}</div>
                    </div>
                </div>
                <div className="component-693 group-inp">
                    <div className="frame-n-a-m-e label">
                        <b className="name">NEW PASSWORD</b>
                    </div>
                    <div className="frame-frame-l-i-n-e-title-comp">
                        <div className="frame-f-r-a-m-e">
                            <div className="line-title-component" />

                            <input
                                className="title5"
                                placeholder=""
                                type="password"
                                name={'newPassword'}
                                {...register('newPassword', {
                                    required: { value: true, message: 'New password is required' },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_+={}[\]\\|;:"<>,./?]).{10,}$/,
                                        message: 'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 numeric character, 1 special character, and be at least 10 characters long',
                                    },
                                })}
                            />
                        </div>
                        <div className="validation">{errors?.newPassword && <small className='text-danger error-text'>{errors?.newPassword.message}</small>}</div>
                    </div>
                </div>
                <div className="component-693 group-inp">
                    <div className="frame-n-a-m-e label">
                        <b className="name">CONFIRM PASSWORD</b>
                    </div>
                    <div className="frame-frame-l-i-n-e-title-comp">
                        <div className="frame-f-r-a-m-e">
                            <div className="line-title-component" />

                            <input
                                className="title5"
                                placeholder=""
                                type="password"
                                name={'repeatPassword'}
                                {...register('repeatPassword', {
                                    required: { value: true, message: 'Repeat password is required' },
                                    validate: value => value === newPassword || 'Passwords do not match'
                                })}
                            />
                        </div>
                        <div className="validation">{errors?.repeatPassword && <small className='text-danger error-text'>{errors?.repeatPassword.message}</small>}</div>
                    </div>
                </div>
            </div>
            <div className='fields_desc'>
                <ul>
                    <li>a minimum of 1 lower case letter [a-z] and</li>
                    <li>a minimum of 1 upper case letter [A-Z] and</li>
                    <li>a minimum of 1 numeric character [0-9] and</li>
                    <li>A minimum of 1 special character: ~`!@#$%^&*()-_+={}[]|\;:"&lt;&gt;,./?</li>
                    <li>Passwords must be at least 10 characters in length, but can be much longer.</li>
                </ul>
                <div className='button_group pt-3'>
                    <button className='add-new-user-hover-edit'>Save</button>
                    <button type='button' className='add-new-user-hover-edit cancel_btn' onClick={() => props.onCancel()}>Cancel</button>
                </div>
            </div>
        </form>
    )
}

export default ResetPassword;
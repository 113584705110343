import $ from 'jquery';

$(document).ready(function() {
    let tagArr = document.getElementsByTagName("input");
    for (let i = 0; i < tagArr.length; i++) {
      tagArr[i].autocomplete = 'off';
    }
});

$(document).on('click',function(e){
    let myTargetElement = e.target;
    if($(myTargetElement).hasClass('add-client')) {
        var scrollTop = $(window).scrollTop(),
            topOffset = $(myTargetElement).offset().top + ($(myTargetElement).outerHeight()/2) - scrollTop,
            leftOffset = $(myTargetElement).offset().left + ($('.add-client').outerWidth()/2),
            $target = $($(myTargetElement).attr('data-modal'));
        $target.css('transform-origin',leftOffset + 'px ' + topOffset + 'px');
        $('.modal-backdrop').addClass('show');
        $target.addClass('is-open');
    }
});

$('#password-update').on('click', function() {
    $('.search-toggle').parent().toggleClass('hel-show');
    
});

const changeSelStyle = () => {
}

const getActiveLink = (item) => {
    let link = (item.link === undefined || item.link === '/') ? '' : item.link;
    let active = false;
    let windowPath = window.location.pathname;
    let windowLength = (windowPath.length - 1)
    let removePreg = windowPath[windowLength]
    if (removePreg === '/') {
        windowPath = windowPath.slice(0, windowLength)
    }

    if (item.children !== undefined) {
        item.children.filter(function (child) {
            let base_path = '/' + child.link;
            if (base_path === windowPath) {
                active = true;
            }
            return active
        });
    } else {
        let base_path = '/' + link;
        if (base_path === windowPath) {
            active = true
        }
    }
    return active;
};

export { getActiveLink, changeSelStyle }

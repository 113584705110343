import {
    INITIAL_STATES,
    GET_PROFILE,
    ADD_USER,
    UPDATE_PROFILE,
    SET_PROFILE,
    SET_GROUPS_MENU
} from "./actionTypes";
import { openNotification } from "../../helpers/globals";

const initialState = {
    user: {
        loading: false,
        errors: [],
        message: "",
        data: {}
    },
    groups: [],
    groupsMenu:[],
    user_types: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    isAuthenticated: localStorage.getItem('h-access_token') ? true : false,
};

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GROUPS':
            return {
                ...state,
                groups: action.payload
            }
            break;
        case SET_GROUPS_MENU:
            return {
                ...state,
                groupsMenu: action.payload
            }
            break;
        case ADD_USER:
            return { ...state, user: action.payload }
            break
        case GET_PROFILE:
            state = { ...state, user: {...state.user, loading: true} }
            break
        case SET_PROFILE:
            state = { ...state, user: {...state.user, data: action.payload} }
            break
        case GET_PROFILE + `_SUCCESS`:
            state = { ...state, user: {...state.user, data: action.payload, loading: false} }
            break        
        case UPDATE_PROFILE:
            state = { ...state, user: {...state.user, loading: true, erros: []} }
            break
        
        case UPDATE_PROFILE + `_SUCCESS`:
            state = { ...state, user: {...state.user, data: action.payload, loading: false, erros: []} }
            openNotification('Alert!', 'Updated')
            break
        case UPDATE_PROFILE + `_SUCCESS`:
            openNotification('Error!', 'Refresh or try again later!')
            break
        case INITIAL_STATES:
            state = {
                ...initialState,
            }
            break;
        default:
            state = { ...state };
            break;
      }
      return state;
}

export default ProfileReducer
import { createApiClient } from '@oneblinktech-org/helios-base';
const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, remove, put } = createApiClient({ apiUrl });


export async function getGroup(groupId) {
    const token = localStorage.getItem('h-access_token')
    try {
        const result = await get(`ithaca/groups/${groupId}`, {
            "Authorization": `Bearer ` + token
        })
        return result
    } catch (e) {
        throw e
    }
}

export async function getGroupMembers(groupId) {
    const token = localStorage.getItem('h-access_token')
    try {
        const result = await get(`ithaca/groups/${groupId}/getMembers`,{
            "Authorization": `Bearer ` + token
        })
        return result
    } catch (e) {
        throw e
    }
}

import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'

function MyGroups({ groupId = '', setGroupId, enableShowAll }) {
    const [groups, setGroups] = useState([])
    const myGroupsMenu = useSelector((state) => state.profile.groups)

    useEffect(() => {
        const filterGroup = async () => {
            if (myGroupsMenu) {
                let allGroup = []
                if (enableShowAll)
                    allGroup = await [{ value: '', label: 'Show All Referrals' }]
                const groups = await myGroupsMenu.map((i) => { return { value: i.id, label: i.name } })
                allGroup = [...allGroup, ...groups]
                await setGroups(allGroup)
            }
        }
        filterGroup()
    }, [myGroupsMenu])

    const onChange = (e) => {
        setGroupId(e)
    }

    return (
        <div>
            {groups?.length > 0 && (
                <Select
                    placeholder="Choose Group"
                    defaultValue={!groupId || groupId === 'undefined' ? enableShowAll ? '' : undefined : parseInt(groupId)}
                    style={{ width: 210 }}
                    options={groups}
                    onChange={(e) => onChange(e)}
                    key={groupId}
                />
            )}
        </div>
    )
}

export default MyGroups
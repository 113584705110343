import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../../helpers/dateFormat'
import { Spin } from 'antd'

const LeftColumn = ({ infoWindowData, isOpen, isLoading, isVisible, items, zoom, setZoom }) => {

  const [data, setData] = useState([]);
  const itemsRef = useRef([]);

  useEffect(() => {
    setData(items)
  }, [items])

  useEffect(() => {
    if (isOpen) {
      if (infoWindowData?.id >= 0 && infoWindowData?.id < itemsRef.current.length) {
        itemsRef.current[infoWindowData.id].scrollIntoView({ behavior: 'smooth' });
        const elem = itemsRef.current[infoWindowData.id]
        elem.classList.add('active');
        setTimeout(async () => {
          elem.classList.remove('active');
        }, 10000)
      }
    }
  }, [infoWindowData?.id, isOpen])

  return (
    <div
      className={`leftColumn show`}
    >
      <Spin spinning={isVisible && isLoading} tip="Loading...">
        <div
          className={`visible group-results d-flex flex-column align-items-center justify-content-start`}>
          {data.length > 0 ?
            data.map((item, index) => (
              <div
                ref={(el) => (itemsRef.current[index] = el)}
                key={index}
                className='item d-flex flex-column align-items-start hover-gray'>
                <div className="content-left d-flex flex-column align-items-start justify-content-start gap-2">
                  <h3 className='mb-0'><Link to={`/group/` + item.id}>{item?.name}</Link></h3>
                  <span>{item?.city ? item?.city + ',' : ''} {item?.state ? item?.state : ''} - {item?.distance ? (parseFloat(item?.distance).toFixed(1) + " miles away") : ''}</span>
                </div>
                <div className="content-center d-flex flex-row align-items-start justfiy-content-start">
                  <div className='content-left'>
                  {item?.upcoming_events.length > 0 && (
                    <div className='flex flex-row align-items-baseline justify-content-start gap-2'>
                      <span className="title-event">Next Meeting:</span>
                      <div className='flex flex-column gap-1' >
                        <div className='gap-1 flex  align-items-center'>
                          <span className="label">Date:</span>
                          <span className="value">{dateFormat(item?.upcoming_events[0]?.start_datetime, 'USER_LOGIN_DATE_FORMAT')}</span>
                        </div>
                        <div className='gap-1 flex  align-items-center'>
                          <span className="label">Time:</span>
                          <span className="value">{dateFormat(item?.upcoming_events[0]?.start_datetime, 'EST_TIME_FORMAT')}</span>
                        </div>
                        <div className='gap-1 flex  align-items-center'>
                          <span className="label">Timezone:</span>
                          <span className="value">{item?.upcoming_events[0]?.timezone}</span>
                        </div>
                      </div>
                    </div>
                    )}
                    </div>
                  <div className="content-right">
                    <Link className="bg-outted-btn" to={`/group/` + item.id}>View Group</Link>
                  </div>
                </div>
              </div>
            ))
            :
            <h5 className='mt-3 p-4'>No groups found for this area. Please zoom out to widen your search area.</h5>
          }
        </div>
      </Spin>
    </div>
  )
}

export default LeftColumn
import React from 'react'
import { useLocation } from 'react-router-dom'

const EventRegister = () => {
  const location = useLocation()

  const isValidUrl = (url) => {
    try {
      new URL(url)
      return true
    } catch (e) {
      return false
    }
  }
  const token = localStorage.getItem('h-access_token')
  const selectedEvent = location.state?.selectedEvent + `?token=${token}`

  return (
    <div>
      {isValidUrl(selectedEvent) ? (
        <iframe src={selectedEvent} style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                title="Event Register"/>
      ) : (
        <div>No event selected</div>
      )}
    </div>
  )
}

export default EventRegister

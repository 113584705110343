import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { HashRouter } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'
import './assets/scss/style.scss'
import './assets/css/global.css'
import 'react-notifications/lib/notifications.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
